<template>
  <v-layout checkout-container d-flex justify-center align-center>
    <v-layout checkout-content d-flex flex-column align-center>
      <v-card>
        <v-progress-linear
          :active="loading"
          :indeterminate="true"
          color="blue"
        ></v-progress-linear>
        <v-card-text :style="`opacity: ${loading ? 0.6 : 1};`">
          <div class="card-layout">
            <div class="order-approved-head">
              <i class="fas fa-check-circle"></i>
              <h1 v-if="method === 'boleto'">Recebemos seu pedido de doação!</h1>
              <h1 v-else-if="method === 'creditCard'">Pagamento aprovado!</h1>
              <h1 v-else>Recebemos seu pedido de doação!</h1>
              <p>Valor total de <strong style="color: rgba(var(--theme-primaria));">R$ {{formatterHelper.formatMoney(cartSuccess ? cartSuccess.totalValue : data.total_amount)}}</strong></p>
              <div class="order-info">
                <p v-if="method === 'boleto'">
                  Estamos aguardando o pagamento, a confirmação poderá levar até 2 dias úteis.
                </p>
                <p v-else-if="method === 'desconto-folha'">
                  Seu pedido n° <strong style="color: rgba(var(--theme-primaria));">{{ data.cart_id }}</strong> de doação por débito em folha foi registrada com sucesso.
                </p>
                <p v-else-if="method === 'creditCard'">
                  Esta doação de nº <strong style="color: rgba(var(--theme-primaria));">{{ data.cart_id }}</strong> foi aprovada pela operadora, em breve você
                  receberá um e-mail com a confirmação do pagamento.
                </p>
                <p v-else>
                  Pedido n° <strong style="color: rgba(var(--theme-primaria));">{{ data.cart_id }}</strong> foi recebido com sucesso.
                </p>
              </div>
            </div>
            <slot v-if="method !== 'none'" :name="method"></slot>
          </div>
          <div class="order-approved-campaign">
            <div class="order-approved-campaign-details">
              <p>Compartilhe a campanha</p>
              <h3 style="color: rgba(var(--theme-primaria));">{{ campaignLandingPageConfig.campaign.name }}</h3>
              <p>através das mídias sociais abaixo:</p>
            </div>
            <div class="order-approved-campaign-social">
              <a :href="GetSocialMediaLink(SocialMedia.Whatsapp, campaign, hasProjectIncentivized ? 'incentivado' : 'semIncentivo')" target="_blank" class="social"
              style="text-decoration: none;">
                <i class="fab fa-whatsapp"></i>
              </a>
              <a :href="GetSocialMediaLink(SocialMedia.Facebook, campaign, hasProjectIncentivized ? 'incentivado' : 'semIncentivo')" target="_blank" class="social"
              style="text-decoration: none;">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a :href="GetSocialMediaLink(SocialMedia.Linkedin, campaign, hasProjectIncentivized ? 'incentivado' : 'semIncentivo')" target="_blank" class="social"
              style="text-decoration: none;">
                <i class="fab fa-linkedin-in"></i>
              </a>
              <!-- <a :href="GetSocialMediaLink(SocialMedia.Twitter, campaign, hasProjectIncentivized ? 'incentivado' : 'semIncentivo')" target="_blank" class="social"
              style="text-decoration: none;">
                <i>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18">
                    <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                  </svg>
                </i> 
              </a> -->
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="box-shadow">
          <v-btn 
              :disabled="loading" 
              class="btn btn-primary outline"
              @click="gotoCampaignLP"
              :style="[!disableButton ? {'color': 'rgba(var(--theme-primaria)) !important'}: {},
                        !disableButton ? {'border-color': 'rgba(var(--theme-primaria)) !important'} : {}]"
              >
            <i class="fal fa-angle-left"></i>
            Voltar para a campanha
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" class="btn btn-primary"
            @click="gotoDashboard"
            :style="!disableButton ? {'background-color': 'rgba(var(--theme-primaria)) !important'} : {}"
          >
            Suas doações
            <i class="fal fa-angle-right"></i>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-layout>
</template>

<style scoped>
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-top: 1px solid #E0E1E2 !important;
}
</style>

<script>
// VUEX
import { mapGetters } from "vuex";

// Helpers
import FormatterHelper from "@/scripts/helpers/formatter.helper";

// Enums
import { Routes } from "@/scripts/models/enums/routes.enum";
import {
  SocialMedia,
  GetSocialMediaLink
} from "@/scripts/models/enums/socialMedias.enum";

// Services
import CartService from "@/scripts/services/cart.service";

export default {
  props: ["method","data"],
  computed: {
    ...mapGetters([
      "checkoutValue",
      "cartSuccess",
      "campaignLandingPageConfig",
      "selectedInstallment"
    ]),
  },
  data() {
    return {
      formatterHelper: new FormatterHelper(),
      loading: false,
      GetSocialMediaLink: GetSocialMediaLink,      
      SocialMedia: SocialMedia,
      hasProjectIncentivized: false,
      campaign: Object,      
      cartService: new CartService(null),
    };
  },
  methods: {
    gotoCampaignLP() {
      this.$router.push({ name: Routes.app.CampaignLandingPage });
    },
    gotoDashboard() {
      this.$router.push({ name: Routes.app.DashboardPF });
    },
  },
  created() {
    if (!this.cartSuccess) return

    const finishedCartId = this.data.cart_id;

    this.campaign = this.cartSuccess.campaign;

    this.cartService.getCartById(finishedCartId).then(data => {
      const haveProjects = data && data.campaign && data.campaign.campaignProjects;

      if (!haveProjects) {
        this.hasProjectIncentivized = false;
        return;
      }

      this.hasProjectIncentivized = data.campaign.campaignProjects.some((el) => el.project.incentiveLawId != null);
    });
    
  }
};
</script>
